import React from "react"
import "./events.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import event_img from '../images/events-img/1-python-folder/background1.jpg'
import MyFooter from "../components/footer.js"
import MyNavbar from "../components/navbar.js"
import Question from "../components/question.js"
import EventBanner from "../components/event-banner.js"
import Warning from "../components/warning.js"
// import MyForm from "../components/forms.js"
import MyCarousel from "../components/carousel.js"
import Partner from "../components/partner.js"


const EventsPage = () => (
  <div className="index">
      <MyNavbar />
      <EventBanner 
        mobile_img = {event_img}
        img = {event_img}
        name = "2 Workshop de Python"
        date = "xx/xx/xxxx"
        description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." 
      />
      <div className="warning-container">
        <div className="events-section-title">AVISOS</div>
        <div className="warning-flex">
          <Warning 
            title = "Data e horário"
            body = "O evento será realizado em bla bla bla bla sanjanjkansjansjansjn"
          />
          <Warning 
            title = "Data e horário"
            body = "O evento será realizado em bla bla bla bla  em tal dia pipipi popopo sanjanjkansjansjansjn"
          />
          <Warning 
            title = "Data e horário"
            body = "O evento será realizado em bla bla bla bla sanjanjkansjansjansjn"
          />
        </div>
      </div>
      <div className="forms-container">
      </div>
      <div className="faq-container">
        <div className="events-section-title" id="faq-title">FAQ</div>
        <div className="faq-flex">
            <Question
                question = "Quem pode se inscrever?"
                answer = "o evento é destinado a meninas de x a x anos"       
            />
            <Question
                question = "Quando será o evento?"
                answer = "o evento ocorrerá dia x de x"       
            />
            <Question
                question = "Onde será o evento?"
                answer = "o evento ocorrerá na TFG (Rua xx número xx)"       
            />
        
            <Question
                question = "Quem pode se inscrever?"
                answer = "o evento é destinado a meninas de x a x anos"       
            />
            <Question
                question = "Quando será o evento?"
                answer = "o evento ocorrerá dia x de x"       
            />
            <Question
                question = "Onde será o evento?"
                answer = "o evento ocorrerá na TFG (Rua xx número xx)"       
            />
        </div>
    </div>
    <Partner ></Partner>
    <MyCarousel />
      <MyFooter />
  </div>
)

export default EventsPage
