import React from "react"
 
import "./warning.css"
 
 
const Warning = (props) =>(
    <div className="warning-box">
        <div className="warning-title">{props.title}</div>
        <div className="warning-body">{props.body}</div>
    </div>
)
 
export default Warning;