import React from "react";
import emmaHead from "../images/logos/wildlife-logo.png"

import "./partner.css"

const Partner = () =>(
    <div className="partner-container">
        <div className="partner-title">PARCERIAS</div>
        <div className="partner-flex">
            <div className="partner-element">
                <img src={emmaHead} alt="" className="partner-logo"/>
                <p className="partner-name">Wildlife Studios</p>
            </div>
            <div className="partner-element">
                <img src={emmaHead} alt="" className="partner-logo"/>
                <p className="partner-name">Wildlife Studios</p>
            </div>
            <div className="partner-element">
                <img src={emmaHead} alt="" className="partner-logo"/>
                <p className="partner-name">Wildlife Studios</p>
            </div>
        </div>
    </div>
)

export default Partner;