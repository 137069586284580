import React from "react"
import "./question.css"

const Question = (props) =>(
    <div className="question-card">
        <div className="question">{props.question}</div>
        <div className="answer">{props.answer}</div>
    </div>
)

export default Question;