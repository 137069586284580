import React, { useState } from "react";
import { Modal } from "react-bootstrap"
import Slider from "react-slick";
import Ana from "../images/members-img/ana.jpg";
import Senec from "../images/events-img/card-img/elas-senec-min.jpg";
import "./carousel.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const MyCarousel = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      responsive:[
          {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              }
          },
          {
            breakpoint: 700,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
          }
      ]
    };
    return (
      <div className="carousel-div">
        <p className="carousel-title">GALERIA</p>
        <Slider {...settings}>
          <div className="carousel-img-div">
            <img className= "carousel-img" alt="" onClick={handleShow} src={ Ana }/>
          </div>
          <div className="carousel-img-div">
            <img className= "carousel-img" alt="" src={ Senec }/>
          </div>
          <div className="carousel-img-div">
            <img className= "carousel-img" alt="" src={ Ana } />
          </div>
          <div className="carousel-img-div">
            <img className= "carousel-img" alt="" src={ Ana } />
          </div>
          <div className="carousel-img-div">
            <img className= "carousel-img" alt="" src={ Ana } />
          </div>
          <div className="carousel-img-div">
            <img className= "carousel-img" alt="" src={ Ana } />
          </div>
          <div className="carousel-img-div">
            <img className= "carousel-img" alt="" src={ Ana } />
          </div>
        </Slider>
        <Modal show={show} onHide={handleClose}>
            <Modal.Body >
                <img alt="" onClick={handleShow} src={ Ana }/>
            </Modal.Body>
        </Modal>
      </div>
    );
}

export default MyCarousel;